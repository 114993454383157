exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advertisements-js": () => import("./../../../src/pages/advertisements.js" /* webpackChunkName: "component---src-pages-advertisements-js" */),
  "component---src-pages-graphicdesign-js": () => import("./../../../src/pages/graphicdesign.js" /* webpackChunkName: "component---src-pages-graphicdesign-js" */),
  "component---src-pages-illustrations-js": () => import("./../../../src/pages/illustrations.js" /* webpackChunkName: "component---src-pages-illustrations-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-uxdesign-js": () => import("./../../../src/pages/uxdesign.js" /* webpackChunkName: "component---src-pages-uxdesign-js" */),
  "component---src-templates-graphic-project-js": () => import("./../../../src/templates/graphicProject.js" /* webpackChunkName: "component---src-templates-graphic-project-js" */)
}

